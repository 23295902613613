<template>
  <div
    role="button"
    :class="[
      $style.burger,
      {
        [$style.toBurger]: toX === false,
        [$style.toX]: toX === true,
      },
    ]"
    @click="toX = !toX"
  >
    <span />
  </div>
</template>
<script lang="ts" setup>
import { useVModel } from "@vueuse/core";

const props = withDefaults(defineProps<{ toX: boolean | null }>(), {
  toX: null,
});

const emit = defineEmits<{
  (event: "update:toX"): void;
}>();
const toX = useVModel(props, "toX", emit, { passive: true });
</script>
<style module lang="scss">
$animationNames: "morphToX", "morphToBurger";
@each $name in $animationNames {
  @keyframes #{$name}Before {
    0% {
      top: 0%;
      transform: translateY(-50%) rotate(0deg);
    }
    50% {
      top: 50%;
      transform: translateY(-50%) rotate(0deg);
    }
    100% {
      top: 50%;
      transform: translateY(-50%) rotate(45deg);
    }
  }
  @keyframes #{$name}After {
    0% {
      bottom: 0%;
      transform: translateY(50%) rotate(0deg);
    }
    50% {
      bottom: 50%;
      transform: translateY(50%) rotate(0deg);
    }
    100% {
      bottom: 50%;
      transform: translateY(50%) rotate(-45deg);
    }
  }
  @keyframes #{$name}Span {
    0%,
    50% {
      opacity: 1;
    }
    51%,
    100% {
      opacity: 0;
    }
  }
}
.burger {
  display: block;
  position: relative;
  width: 30px;
  height: 20px;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;

  span {
    display: block;
    width: 100%;
    height: 0;
    border-top: solid 3px $signalorange;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 3px;
    border-radius: 3px;
    background-color: $signalorange;
  }

  &::before {
    top: 0;
    transform: translateY(-50%);
  }
  &::after {
    bottom: 0;
    transform: translateY(50%);
  }

  &.toX {
    span {
      animation: morphToXSpan 0.5s forwards;
    }

    &::before {
      animation: morphToXBefore 0.5s forwards;
    }
    &::after {
      animation: morphToXAfter 0.5s forwards;
    }
  }
  &.toBurger {
    span {
      animation: morphToBurgerSpan 0.5s reverse;
    }

    &::before {
      animation: morphToBurgerBefore 0.5s reverse;
    }
    &::after {
      animation: morphToBurgerAfter 0.5s reverse;
    }
  }
}
</style>
