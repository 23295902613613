<template>
  <header :class="$style.mainHeader">
    <ul :class="$style.logoContainer">
      <NuxtLink href="/">
        <LogoSvg :class="$style.logo" />
      </NuxtLink>
      <li>
        <BurgerButton v-model:to-x="isOpen" class="me-md-s" />
      </li>
    </ul>
    <nav :class="[$style.navigation, { [$style.isOpen]: isOpen }]">
      <ul :class="$style.navList" class="pt-l px-s pt-md-xxl ps-md-ml">
        <li v-for="(node, index) in navNodes" :key="index">
          <NuxtLink :class="$style.navLink" :to="node.url" @click="isOpen = false">
            {{ node.label }}
          </NuxtLink>
          <nav v-if="node.children.length > 0" :class="$style.subNavList">
            <li v-for="(subNode, index) in node.children" :key="index">
              <NuxtLink :class="[$style.navLink, $style.anchorLink]" :to="subNode.url" @click="isOpen = false">
                {{ subNode.label }}
              </NuxtLink>
            </li>
          </nav>
        </li>
      </ul>
      <div :class="$style.navBackground" @click="isOpen = false" />
    </nav>
  </header>
</template>

<script setup lang="ts">
import { computed, useAsyncQuery } from "#imports";
import { ref } from "vue";

import LogoSvg from "~/assets/images/svg/logo-positive.svg?component";
import { useAnchorNavItems } from "~/composables/anchorNavigation";
import { graphql } from "../graphql";
import type { NavQuery } from "../graphql/graphql";
import { slugify } from "pulls-website-core";

const isOpen = ref<boolean | null>(null);

const anchorNavItems = useAnchorNavItems();

const { data } = await useAsyncQuery<NavQuery>(
  graphql(`
    query nav {
      Navigation {
        pages {
          page {
            id
            title
            slug
          }
        }
      }
    }
  `),
);

const navNodes = computed(() => {
  return data.value?.Navigation.pages.map((node) => {
    const page = node.page;
    return {
      label: page.title,
      url: `/${page.slug}`,
      children:
        anchorNavItems.value[page.slug]?.map((a) => ({
          label: a.name,
          url: `#${slugify(a.name)}`,
        })) ?? [],
    };
  });
});
</script>

<style module lang="scss">
.mainHeader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 200;
}
.logoContainer {
  position: absolute;
  top: map-get($spacers-map, "s");
  left: map-get($spacers-map, "s");
  right: map-get($spacers-map, "s");
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  z-index: 4;
  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  @include media-breakpoint-up(md) {
    top: map-get($spacers-map, "sm");
    left: map-get($spacers-map, "sm");
    right: map-get($spacers-map, "sm");
    justify-content: right;
    flex-direction: row-reverse;
  }
}
.logo {
  height: 35px;
}

nav.navigation {
  @extend %text-one-600-uppercase-white;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1;

  .navList {
    position: fixed;
    top: 5px;
    right: 5px;
    bottom: 5px;
    left: 5px;
    margin: 0;
    padding: 0;
    z-index: 2;
    text-align: right;
    background-color: $purple-900;
    overflow-y: auto;
    overflow: overlay;
    box-sizing: border-box;
    border-radius: $default-border-radius;
    opacity: 0;
    transform: translate(100%, 0);
    transition:
      transform 0.5s,
      opacity 0.5s;

    li {
      margin: 0 0 map-get($spacers-map, "sm") 0;
      padding: 0;
      list-style: none;
      position: relative;
      display: block;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .subNavList {
      padding-top: 1rem;
    }

    .navLink {
      display: inline-block;
      text-decoration: none;
      position: relative;
      color: $ochre-500;

      &.anchorLink {
        margin-left: 1rem;
        color: $white;
      }

      &::after {
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        height: 2px;
        background-color: $white;
        display: none;
        bottom: -7px;
        left: 0;
      }

      &:hover {
        &::after {
          display: block;
        }
      }
    }

    @include media-breakpoint-up(md) {
      width: 456px;
      top: 10px;
      right: 10px;
      bottom: 10px;
      left: auto;
      text-align: left;
      margin-right: auto;
    }
  }
  .navBackground {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba($black, 0.8);
    opacity: 0;
    transition: opacity 0.5s;
    pointer-events: none;
  }

  &.isOpen {
    .navList {
      opacity: 1;
      transform: translate(0%, 0);
    }
    .navBackground {
      opacity: 1;
      pointer-events: all;
    }
  }
}
</style>
