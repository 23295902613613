<template>
  <div :class="$style.main">
    <div :class="$style.before" />
    <MainHeader />
    <div :class="$style.contentContainer">
      <div :class="$style.inner">
        <slot />
        <MainFooter />
      </div>
    </div>
    <div :class="$style.after" />
  </div>
</template>

<script lang="ts" setup></script>

<style module lang="scss">
:root {
  --outer-border-width: 5px;

  @include media-breakpoint-up(md) {
    --outer-border-width: 10px;
  }
}

.main {
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  padding: 0 var(--outer-border-width);
  display: flex;
  flex-direction: column;

  .before {
    position: sticky;
    top: 0;
    z-index: 100;
    pointer-events: none;
    height: 1px;
    background-color: $white;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: calc(-1 * var(--outer-border-width));
      left: calc(-1 * var(--outer-border-width));
      height: 100px;
      border: solid $white calc(var(--outer-border-width) * 1);
      border-bottom: none;
      border-radius: calc(var(--outer-border-width) + #{$default-border-radius});
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      pointer-events: none;
    }
  }
  .after {
    position: sticky;
    bottom: 0;
    z-index: 100;
    pointer-events: none;
    height: 1px;
    background-color: $white;

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      right: calc(-1 * var(--outer-border-width));
      left: calc(-1 * var(--outer-border-width));
      height: 100px;
      border: solid $white calc(var(--outer-border-width) * 1);
      border-top: none;
      border-radius: calc(var(--outer-border-width) + #{$default-border-radius});
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      pointer-events: none;
    }
  }

  .contentContainer {
    width: 100%;
    height: 100%;
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .inner {
      flex-grow: 1;
      display: grid;
      grid-template-rows: 1fr auto;
      gap: map-get($spacers-map, "xs") 0;
    }
  }
}
</style>
