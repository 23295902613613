<template>
  <footer v-if="footerData" :class="$style.main" class="pt-ml pb-l py-md-l">
    <div :class="$style.grid" class="container px-0">
      <div>
        <LogoSvg :class="$style.logo" /> by
        <NuxtLink href="https://www.itsleif.ch/" target="_blank"> Leif AG </NuxtLink>
      </div>
      <div class="richtext" v-html="getHtmlFromLexicalJson(footerData.address)" />
      <div :class="$style.titleCell" class="py-xs">{{ footerData.contact.title }}</div>
      <div>
        <NuxtLink class="d-block" :to="`tel:${footerData.contact.phoneNumber}`">{{
          footerData.contact.phoneNumber
        }}</NuxtLink>
        <NuxtLink :to="footerData.contact.emailContent" target="_blank" class="d-block">
          {{ footerData.contact.email }}
        </NuxtLink>
        <NuxtLink :class="$style.icon" :to="footerData.socials.linkedinUrl" target="_blank">
          <SvgIcon icon="linkedin-round-icon" size="32px" />
        </NuxtLink>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { computed, useAsyncQuery } from "#imports";
import LogoSvg from "~/assets/images/svg/logo-positive.svg?component";
import { getHtmlFromLexicalJson } from "~/utils/getHtmlFromLexicalJson";
import { graphql } from "../graphql";
import type { FooterDocument } from "~/graphql/graphql";

const { data } = await useAsyncQuery<typeof FooterDocument>(
  graphql(`
    query footer {
      Footer {
        address
        contact {
          title
          phoneNumber
          email
          emailContent
        }
        socials {
          linkedinUrl
        }
      }
    }
  `),
);

const footerData = computed(() => data.value?.Footer);
</script>

<style module lang="scss">
.main {
  @extend %footertext-one-600-purple-700;
  background-color: $grey-300;
  border-radius: $default-border-radius;
  a {
    color: $purple-700;
    &:hover {
      color: $purple-900;
    }
  }
}

.grid {
  display: grid;
  gap: $grid-gutter-width map-get($spacers-map, "sm");
  grid-template-columns: 1fr;
  @include media-breakpoint-down(md) {
    margin: map-get($spacers-map, "s") !important;
  }
  @include media-breakpoint-up(md) {
    grid-template-columns: 1fr 1fr;
    .titleCell {
      grid-column: 2;
      grid-row: 1;
    }
  }
}
.icon {
  display: inline-block;
  margin-top: map-get($spacers-map, "ml");

  path {
    fill: $purple-700;
  }

  @include media-breakpoint-up(md) {
    float: right;
    margin-top: calc(map-get($spacers-map, "sm") * -1);
  }

  &:hover {
    path {
      fill: $purple-900;
    }
  }
}
.logo {
  height: map-get($spacers-map, "sm");
}
</style>
